import Vue from 'vue'
import i18n from '@/locale'
import { LocaleMessages } from 'vue-i18n'

export const imageTypes = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.JPG', '.JPEG', '.PBG', '.GIF', '.BMP']
export const fileTypes = ['.csv']

export const videoTypes = ['.mp4', '.MP4']
export const excelTypes = ['.xls', '.xlsx']

/**
 * 上传前 处理文件
 * @param file 上传文件
 * @param type 0：所有文件 1：图片 2：视频 3：音频 4：csv 5：excel文件
 */
export const uploadHandle = (file: File, type: number = 0, size: number = 0) => {
    let fileType: string | string[] = 'other'
    let fileSize: number = 0
    let typeMsg: LocaleMessages | string = ''
    // type 0：所有文件 1：图片 2：视频 3：音频 4：csv 5：excel文件
    switch (type) {
        case 1:
            fileType = 'image'
            typeMsg = i18n.t('uploadHandle.imageMsg')
            fileSize = 10
            break
        case 2:
            fileType = 'video'
            typeMsg = i18n.t('uploadHandle.videoMsg')
            fileSize = 200
            break
        case 3:
            fileType = 'audio'
            typeMsg = i18n.t('uploadHandle.audioMsg')
            fileSize = 100
            break
        case 4:
            fileType = ['csv', 'vnd.ms-excel']
            typeMsg = i18n.t('uploadHandle.csvMsg')
            fileSize = 10
            break
        case 5:
            fileType = 'officedocument' 
            typeMsg = i18n.t('uploadHandle.excelMsg')
            fileSize = 0
            break
        default:
            break
    }

    if (size > 0) {
        fileSize = size
    }

    
    if (type !== 0) {
        if (Array.isArray(fileType) && !fileType.some(v => file.type.includes(v))) {
            Vue.prototype.$message.error(typeMsg)
            return false
        } else if (!Array.isArray(fileType) && !file.type.includes(<string><unknown>fileType)) {
            Vue.prototype.$message.error(typeMsg)
            return false
        }
    }
    
    if (fileSize > 0 && file.size / 1024 / 1024 > fileSize) {
        Vue.prototype.$message.error(i18n.t('uploadHandle.sizeMsg', { fileSize }))
        return false
    }
    return true
}

/**
 * 获取文件后缀
 * @param file
 */
const suffixHandle = (file: any) => {
    const upFileName = file.name
    const index1 = upFileName.lastIndexOf('.')
    const index2 = upFileName.length
    const suffix = `${new Date().getTime()}.${upFileName.substring(
        index1 + 1,
        index2
    )}`
    return suffix
}
